import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Paper, Typography, Box, TextField, Button, Modal, MenuItem, Select, FormControl, InputLabel, Snackbar, Alert } from "@mui/material";
import axios from "axios";
import VisibilityIcon from '@mui/icons-material/Visibility';
const apiUrl = process.env.REACT_APP_API_URL;

const Delivery = () => {
    const [salesData, setSalesData] = useState([]);
    const [filteredSales, setFilteredSales] = useState([]);
    const [searchInvoice, setSearchInvoice] = useState("");
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [formValues, setFormValues] = useState({
        kode_delivery: "",
        track_number_ds: "",
        status_ds: "", // Status is initially empty
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false); // For Snackbar
    const [snackbarMessage, setSnackbarMessage] = useState(""); // Snackbar message

    // Fungsi untuk menangani pencarian invoice
    const handleSearch = (e) => {
        const searchValue = e.target.value;
        setSearchInvoice(searchValue);

        if (searchValue) {
            const filtered = salesData.filter((sale) =>
                sale.invoice_sales.toLowerCase().includes(searchValue.toLowerCase())
            );
            setFilteredSales(filtered);
        } else {
            setFilteredSales(salesData);
        }
    };

    // Fungsi untuk membuka modal dan memanggil API
    const handleOpenModal = async (invoice_sales) => {
        const formData = new FormData();
        formData.append("invoice", invoice_sales);

        try {
            const response = await axios.post(
                `${apiUrl}api/sales_delivery/detail`,
                formData,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                }
            );

            if (response.data && response.data.data.length > 0) {
                const modalDetails = response.data.data[0];
                setModalData(modalDetails);

                // Set nilai awal form sesuai data dari API
                setFormValues({
                    kode_delivery: modalDetails.kode_delivery || "",
                    track_number_ds: modalDetails.track_number_ds || "",
                    status_ds: modalDetails.status_ds || "", // Update with initial status
                });
            } else {
                setModalData(null);
            }
            setOpenModal(true);
        } catch (error) {
            console.error("Error fetching data for modal:", error);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setModalData(null);
        setFormValues({
            kode_delivery: "",
            track_number_ds: "",
            status_ds: "",
        });
    };

    // Fungsi untuk menangani perubahan pada input form
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    // Fungsi untuk menyimpan data ke API
    const handleSave = async () => {
        const formData = new FormData();
        formData.append("invoice", modalData.invoice_sales);
        formData.append("delivery_number", formValues.track_number_ds);
        formData.append("status", formValues.status_ds);

        try {
            const response = await axios.post(
                `${apiUrl}api/sales_delivery/create`,
                formData,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                }
            );

            if (response.data.response === 200) {
                setSnackbarMessage("Data berhasil disimpan!");
                setSnackbarOpen(true);
                // Refresh the data after saving
                fetchSalesData();
                handleCloseModal();
            } else {
                setSnackbarMessage("Gagal menyimpan data!");
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error("Error saving data:", error);
            setSnackbarMessage("Terjadi kesalahan saat menyimpan data.");
            setSnackbarOpen(true);
        }
    };
    // Kolom untuk DataTable
    const columns = [
        {
            name: "#",
            selector: (row) => row.number,
            sortable: true,
            width: "80px",
        },
        {
            name: "Invoice",
            selector: (row) => row.invoice_sales,
            sortable: true,
        },
        {
            name: "Delivery",
            selector: (row) => row.kode_delivery,
            sortable: true,
        },
        {
            name: "Status Delivery",
            selector: (row) => row.status_ds,
            sortable: true,
        },
        {
            name: "Total Amount",
            selector: (row) => parseFloat(row.total_amount).toLocaleString(),
            sortable: true,
        },
        {
            name: "Final Total",
            selector: (row) => parseFloat(row.final_total).toLocaleString(),
            sortable: true,
        },
        {
            name: "Payment Status",
            selector: (row) => row.payment_status,
        },
        {
            name: "Transaction Date",
            selector: (row) => new Date(row.transaction_date).toLocaleString(),
            sortable: true,
        },
        {
            name: "Actions",
            cell: (row) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal(row.invoice_sales)}
                ><VisibilityIcon />
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    // Fetch data dari API
    const fetchSalesData = async () => {
        try {
            const response = await axios.get(`${apiUrl}api/sales_delivery`);
            setSalesData(response.data);
            setFilteredSales(response.data);
        } catch (error) {
            console.error("Error fetching sales data:", error);
        } finally {
            setLoading(false);
        }
    };

    // Menambahkan nomor urut pada data yang difilter
    const salesWithNumbering = filteredSales.map((sale, index) => ({
        number: index + 1,
        ...sale,
    }));

    // Initial data fetch
    useEffect(() => {
        fetchSalesData();
    }, []);

    return (
        <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, paddingTop: "20px" }}>
            <Typography variant="h4" gutterBottom>
                Delivery Document
            </Typography>
            <Box sx={{ height: "calc(100vh - 300px)", overflowY: "auto", mb: 2 }}>
                <TextField
                    label="Cari Invoice"
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: 2 }}
                    value={searchInvoice}
                    onChange={handleSearch}
                />
                <DataTable
                    columns={columns}
                    data={salesWithNumbering}
                    progressPending={loading}
                    pagination
                    highlightOnHover
                    striped
                    responsive
                    customStyles={{
                        rows: {
                            style: {
                                fontSize: '14px',
                            },
                        },
                        headCells: {
                            style: {
                                backgroundColor: '#f4f6f8',
                                fontWeight: 'bold',
                            },
                        },
                        cells: {
                            style: {
                                fontSize: '14px',
                            },
                        },
                    }}
                />
            </Box>

            {/* Modal untuk menampilkan data */}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Paper
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        padding: 4,
                        maxWidth: 500,
                        width: "100%",
                    }}
                >
                    <Typography variant="h6" id="modal-title" gutterBottom>
                        Edit Delivery Details
                    </Typography>
                    {modalData ? (
                        <Box>
                            <TextField
                                label="Track Number"
                                name="track_number_ds"
                                value={formValues.track_number_ds}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"

                            />
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Status</InputLabel>
                                <Select
                                    name="status_ds"
                                    value={formValues.status_ds}
                                    onChange={handleInputChange}
                                >

                                    <MenuItem value="Pesanan Diterima">Pesanan Diterima</MenuItem>
                                    <MenuItem value="Prepare Packing">Prepare Packing</MenuItem>
                                    <MenuItem value="Barang Diserahkan ke Ekspedisi">Barang Diserahkan ke Ekspedisi</MenuItem>
                                    <MenuItem value="Sedang Dalam Pengiriman">Sedang Dalam Pengiriman</MenuItem>
                                    <MenuItem value="Telah Diterima">Telah Diterima</MenuItem>
                                </Select>
                            </FormControl>
                            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
                                <Button variant="outlined" onClick={handleCloseModal}>
                                    Cancel
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleSave}>
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    ) : (
                        <Typography>Loading...</Typography>
                    )}
                </Paper>
            </Modal>

            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarMessage.includes("Gagal") ? "error" : "success"} sx={{ width: "100%" }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default Delivery;
