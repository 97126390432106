// src/PpnManagement.js
import React, { useState, useEffect } from 'react';
import {
  getPpnList,
  createPpn,
  updatePpn,
  deletePpn
} from '../apis/Api';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, TextField, Grid, Dialog, DialogActions, DialogContent,
  DialogTitle, Typography
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';

function Ppn() {
  const [ppnList, setPpnList] = useState([]);
  const [bobotPpn, setBobotPpn] = useState('');
  const [editId, setEditId] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchPpnList();
  }, []);

  const fetchPpnList = async () => {
    try {
      const response = await getPpnList();
      setPpnList(response.data);
    } catch (error) {
      console.error('Error fetching PPN list:', error);
    }
  };

  const handleOpenDialog = () => setOpen(true);
  const handleCloseDialog = () => {
    setOpen(false);
    setEditId(null);
    setBobotPpn('');
  };

  const handleAddOrUpdate = async (e) => {
    e.preventDefault();
    const data = { bobot_ppn: bobotPpn };

    try {
      if (editId) {
        await updatePpn(editId, data);
      } else {
        await createPpn(data);
      }
      fetchPpnList();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving PPN:', error);
    }
  };

  const handleEdit = (ppn) => {
    setBobotPpn(ppn.bobot_ppn);
    setEditId(ppn.id_ppn);
    handleOpenDialog();
  };

  const handleDelete = async (id) => {
    try {
      await deletePpn(id);
      fetchPpnList();
    } catch (error) {
      console.error('Error deleting PPN:', error);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>PPN Management</Typography>

      <Grid container justifyContent="flex-end" style={{ marginBottom: '20px' }}>
        {/* <Button variant="contained" color="primary" onClick={handleOpenDialog}>Add PPN</Button> */}
      </Grid>

      <TableContainer component={Paper}>
        <Table aria-label="ppn table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>PPN</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ppnList.map((ppn) => (
              <TableRow key={ppn.id_ppn}>
                <TableCell>{ppn.id_ppn}</TableCell>
                <TableCell>{ppn.bobot_ppn}</TableCell>
                <TableCell align="center">
                  <Button
                    startIcon={<Edit />}
                    onClick={() => handleEdit(ppn)}
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: '8px' }}
                  >
                    Edit
                  </Button>
                  <Button
                    startIcon={<Delete />}
                    onClick={() => handleDelete(ppn.id_ppn)}
                    variant="outlined"
                    color="secondary"
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog for Add/Edit */}
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>{editId ? 'Edit PPN' : 'Add PPN'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Bobot PPN"
            type="number"
            fullWidth
            variant="outlined"
            value={bobotPpn}
            onChange={(e) => setBobotPpn(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">Cancel</Button>
          <Button onClick={handleAddOrUpdate} color="primary">{editId ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Ppn;
