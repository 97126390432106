import React, { useState } from 'react';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  Divider,
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  Collapse,
} from '@mui/material';
import {
  Home as HomeIcon,
  Inventory as InventoryIcon,
  DocumentScanner as DocumentScannerIcon,
  PhotoLibrary as PhotoLibraryIcon,
  LocalShipping as LocalShippingIcon,
  Info as InfoIcon,
  Logout as LogoutIcon,
  AccountCircle as AccountCircleIcon,
  Menu as MenuIcon,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const Headeradmin = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState({});
  const navigate = useNavigate();

  const admin = JSON.parse(localStorage.getItem('admin'));
  const user = JSON.parse(localStorage.getItem('user'));

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleProfileDialogOpen = () => {
    setProfileDialogOpen(true);
  };

  const handleProfileDialogClose = () => {
    setProfileDialogOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('admin');
    window.location.reload();
    navigate('/admin');
  };

  const handleToggleMenu = (menu) => {
    setOpenMenu((prev) => ({ ...prev, [menu]: !prev[menu] }));
  };

  const menuItems = admin
    ? [
      {
        text: 'Home',
        icon: <HomeIcon />,
        onClick: () => navigate('/'),
      },
      {
        text: 'Dashboard',
        icon: <HomeIcon />,
        onClick: () => navigate('/admin/dashboard'),
      },
      {
        text: 'Product',
        icon: <InventoryIcon />,
        children: [
          { text: 'Inventory', onClick: () => navigate('/admin/inventory') },
          { text: 'Document', onClick: () => navigate('/admin/document') },
          { text: 'Photo', onClick: () => navigate('/admin/photo') },
        ],
      },
      {
        text: 'Delivery',
        icon: <LocalShippingIcon />,
        children: [
          { text: 'Delivery Product', onClick: () => navigate('/admin/delivery') },
        ],
      },
      {
        text: 'Settings',
        icon: <InfoIcon />,
        children: [
          { text: 'Site About', onClick: () => navigate('/admin/about') },
          { text: 'User List', onClick: () => navigate('/admin/users') },
        ],
      },
      {
        text: 'Logout',
        icon: <LogoutIcon />,
        onClick: handleLogout,
      },
    ]
    : user
      ? [
        {
          text: 'Profil',
          icon: <AccountCircleIcon />,
          onClick: handleProfileDialogOpen,
        },
        {
          text: 'Logout',
          icon: <LogoutIcon />,
          onClick: handleLogout,
        },
      ]
      : [
        {
          text: 'Login',
          icon: <AccountCircleIcon />,
          onClick: () => navigate('/login'),
        },
      ];

  return (
    <Box>
      {/* Floating Button */}
      <IconButton
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer}
        sx={{
          position: drawerOpen ? 'sticky' : 'fixed',
          top: 16,
          left: 0,
          zIndex: 1201,
        }}
      >
        <MenuIcon />
      </IconButton>

      {/* Sidebar Drawer */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        PaperProps={{
          sx: {
            width: 250,

            backgroundColor: '#000b3e',
            color: '#FFF',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
          <Avatar
            alt="Admin/User"
            src={`${apiUrl}/uploads/user_photos/${user?.profile_photo || admin?.profile_photo || ''}`}
            sx={{ width: 56, height: 56, mr: 2 }}
          />
          <Box>
            <Typography variant="h6">{admin ? 'Admin Panel' : user?.fullname || 'Guest'}</Typography>
            <Typography variant="body2">{admin ? 'Administrator' : user?.email || 'Welcome!'}</Typography>
          </Box>
        </Box>
        <Divider sx={{ bgcolor: '#FFF' }} />

        <List>
          {menuItems.map((item, index) =>
            item.children ? (
              <React.Fragment key={index}>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleToggleMenu(item.text)}>
                    <ListItemIcon sx={{ color: '#FFF' }}>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                    {openMenu[item.text] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                </ListItem>
                <Collapse in={openMenu[item.text]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.children.map((subItem, subIndex) => (
                      <ListItemButton
                        key={subIndex}
                        sx={{ pl: 4 }}
                        onClick={subItem.onClick}
                      >
                        <ListItemText primary={subItem.text} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ) : (
              <ListItem key={index} disablePadding>
                <ListItemButton onClick={item.onClick}>
                  <ListItemIcon sx={{ color: '#FFF' }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
      </Drawer>

      {/* Profile Dialog */}
      <Dialog open={profileDialogOpen} onClose={handleProfileDialogClose}>
        <DialogTitle>Profil Pengguna</DialogTitle>
        <DialogContent>
          {user ? (
            <Box>
              <Avatar
                alt="Profile Photo"
                src={`${apiUrl}/uploads/user_photos/${user.profile_photo}`}
                sx={{ width: 100, height: 100, mx: 'auto', mb: 2 }}
              />
              <Typography variant="h6" align="center">{user.fullname}</Typography>
              <Typography variant="body1" align="center">{user.email}</Typography>
            </Box>
          ) : (
            <Typography>Data profil tidak ditemukan.</Typography>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Headeradmin;
