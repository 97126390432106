// src/About.js
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill'; // Import react-quill
import 'react-quill/dist/quill.snow.css'; // Import style editor
import {
  Container, Grid, Card, CardMedia, CardContent,
  Typography, CardActions, Button, Dialog,
  DialogActions, DialogContent, DialogTitle
} from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL; // Ganti dengan URL endpoint API Anda

const CrudAbout = () => {
  const [aboutList, setAboutList] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({ photo_about: '', text_about: '' });
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    console.log('Fetching about list...');
    fetchAboutList();
  }, []);

  const fetchAboutList = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/about/`);
      const data = await response.json();
      console.log('Fetched about list:', data); // Log data untuk memeriksa
      setAboutList(data);
    } catch (error) {
      console.error('Error fetching about data:', error);
    }
  };

  const handleOpen = (item = {}) => {
    setEditId(item.id_about || null);
    setFormData({
      photo_about: item.photo_about || '',
      text_about: item.text_about || ''
    });
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleTextChange = (value) => {
    setFormData({ ...formData, text_about: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, photo_about: e.target.files[0] });
  };

  const handleSave = async () => {
    const endpoint = editId ? `${apiUrl}api/about/update/${editId}` : `${apiUrl}api/about/create`;
    const method = editId ? 'POST' : 'POST';

    const formDataObj = new FormData();
    formDataObj.append('photo_about', formData.photo_about);
    formDataObj.append('text_about', formData.text_about);

    try {
      const response = await fetch(endpoint, {
        method,
        body: formDataObj,
      });
      const result = await response.json();
      if (result.message) {
        console.log(result.message);
      }
      fetchAboutList();
      handleClose();
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${apiUrl}delete/${id}`, { method: 'DELETE' });
      const result = await response.json();
      if (result.message) {
        console.log(result.message);
      }
      fetchAboutList();
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  return (
    <Container sx={{ mt: 4 ,paddingBottom:'50px'}}>
      <Typography variant="h4" component="h1" gutterBottom>About Management</Typography>
      {/* <Button variant="contained" color="primary" onClick={() => handleOpen()}>Add New</Button> */}
      
      <Grid container spacing={4} sx={{ mt: 2 }}>
        {aboutList.map(item => (
          <Grid item key={item.id_about} xs={12} sm={12} md={12}>
            <Card>
              <CardMedia
                component="img"
                height="400"
                image={`${apiUrl}uploads/header/${item.photo_about}`}
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                <span dangerouslySetInnerHTML={{ __html: item.text_about }} />
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="medium" color="primary" variant='outlined' onClick={() => handleOpen(item)}>Edit</Button>
                <Button size="medium" color="warning" variant='outlined' onClick={() => handleDelete(item.id_about)}>Delete</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Dialog untuk tambah/edit data */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editId ? 'Edit About' : 'Add New About'}</DialogTitle>
        <DialogContent>
          {/* Gunakan ReactQuill untuk pengeditan teks */}
          <ReactQuill
            value={formData.text_about}
            onChange={handleTextChange}
            placeholder="Type here..."
          />
          <input
            type="file"
            name="photo_about"
            onChange={handleFileChange}
            style={{ marginTop: '20px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Cancel</Button>
          <Button onClick={handleSave} color="primary">{editId ? 'Update' : 'Save'}</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CrudAbout;
