import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Paper, Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;  // Pastikan ini sesuai dengan URL backend Anda

const DashboardAdmin = () => {
    const [revenueData, setRevenueData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // State untuk tahun yang dipilih

    const formatPrice = (price) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
        }).format(price);
    };

    useEffect(() => {
        const fetchRevenue = async () => {
            try {
                const response = await axios.get(`${apiUrl}api/revenue`);
                setRevenueData(response.data.data); // Simpan data revenue di state
                setLoading(false);
            } catch (error) {
                console.error("Error fetching revenue data:", error);
                setLoading(false);
            }
        };

        fetchRevenue();
    }, []);

    // Jika data sedang diambil, tampilkan spinner loading
    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    const downloadSalesReport = async (tahun) => {

        // Buat URL absolut dengan aman
        const endpoint = `${apiUrl}/SalesReport/download_excel/${tahun}`;
        const requestURL = new URL(endpoint, apiUrl).toString();
        window.open(requestURL, '_blank');
    };



    return (
        <Box sx={{ padding: 4, backgroundColor: '#f4f6f8', minHeight: '100vh' }}>
            <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold', color: '#333' }}>
                Revenue Dashboard {new Date().getFullYear()}
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 3, // Jarak antar kotak
                    flexWrap: 'wrap', // Agar responsif di layar kecil
                }}
            >
                {revenueData.map((revenue, index) => (
                    <React.Fragment key={index}>
                        <Paper
                            elevation={3}
                            sx={{
                                flex: '1 1 calc(50% - 16px)',
                                padding: 3,
                                borderRadius: 2,
                                backgroundColor: '#fff',
                            }}
                        >
                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1a237e', mb: 1 }}>
                                Revenue
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 'bold',
                                    color: '#d32f2f',
                                    textAlign: 'center',
                                }}
                            >
                                {formatPrice(revenue.revenue)}
                            </Typography>
                        </Paper>

                        <Paper
                            elevation={3}
                            sx={{
                                flex: '1 1 calc(50% - 16px)',
                                padding: 3,
                                borderRadius: 2,
                                backgroundColor: '#fff',
                            }}
                        >
                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1a237e', mb: 1 }}>
                                Last Transaction Date
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 'bold',
                                    color: '#455a64',
                                    textAlign: 'center',
                                }}
                            >
                                {new Date(revenue.transaction_date).toLocaleString()}
                            </Typography>
                        </Paper>
                    </React.Fragment>
                ))}
            </Box>

            {/* Select Input for Year */}
            <Paper
                elevation={3}
                sx={{
                    flex: '1 1 calc(50% - 16px)',
                    padding: 3,
                    borderRadius: 2,
                    backgroundColor: '#fff',
                }}
            >
                <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold', color: '#333' }}>
                    Sales Report
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 3, // Jarak antar kotak
                        flexWrap: 'wrap', // Agar responsif di layar kecil
                    }}
                >
                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel id="select-year-label">Year</InputLabel>
                        <Select
                            labelId="select-year-label"
                            value={selectedYear}
                            label="Year"
                            onChange={(e) => setSelectedYear(e.target.value)} // Update selected year
                        >
                            {[2020, 2021, 2022, 2023, 2024].map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => downloadSalesReport(selectedYear)} // Pass selected year
                        sx={{ fontWeight: 'bold', textTransform: 'none', padding: '10px 20px' }}
                    >
                        Download Report
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default DashboardAdmin;
