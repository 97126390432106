import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

const CheckoutConfirmationDialog = ({ open, onClose, onConfirm }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Konfirmasi Pembayaran</DialogTitle>
            <DialogContent>
                Apakah Anda yakin ingin melanjutkan checkout dan melakukan pembayaran?
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Batal
                </Button>
                <Button onClick={onConfirm} color="primary">
                    Ya, Lanjutkan
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CheckoutConfirmationDialog;
