import React, { useEffect, useState } from 'react';
import { Button, Chip, Paper, Typography, Box, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Stepper, Step, StepLabel } from '@mui/material';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import InvoiceDetail from './InvoiceDetail';
import ErrorIcon from '@mui/icons-material/Error'; // Import Error Icon


const apiUrl = process.env.REACT_APP_API_URL;
const SalesDetail = () => {
    const [sales, setSales] = useState([]);
    const [filteredSales, setFilteredSales] = useState([]);
    const [searchInvoice, setSearchInvoice] = useState('');
    const [open, setOpen] = useState(false);
    const [selectedSales, setSelectedSales] = useState(null);
    const [deliveryInfo, setDeliveryInfo] = useState(null);
    const [deliveryDialogOpen, setDeliveryDialogOpen] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const [formValues, setFormValues] = useState({ track_number_ds: '', status_ds: '' });

    useEffect(() => {
        const fetchSales = async () => {
            try {
                const response = await axios.get(`${apiUrl}api/sales/${user.id_user}`);
                console.log('Sales data fetched:', response.data); // Debugging log
                setSales(response.data);
                setFilteredSales(response.data);
            } catch (error) {
                console.error('Error fetching sales data:', error);
            }
        };

        fetchSales();
    }, []);
    const handleSearch = (e) => {
        const searchValue = e.target.value;
        setSearchInvoice(searchValue);

        if (searchValue) {
            const filtered = sales.filter((sale) =>
                sale.invoice_sales.toLowerCase().includes(searchValue.toLowerCase())
            );
            setFilteredSales(filtered);
        } else {
            setFilteredSales(sales);
        }
    };

    const handleClickOpen = (sale) => {
        setSelectedSales(sale);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedSales(null);
    };

    const handleDeliveryClick = (sale) => {
        setDeliveryInfo({
            invoice: sale.invoice_sales,
            status: sale.status_ds || 'Not Found',
            lastUpdate: sale.date_update || 'Not Available',
            kodeDelivery: sale.kode_delivery || 'Not Available',
        });
        setSelectedSales(sale);
        setDeliveryDialogOpen(true);
    };

    const handleCloseDeliveryDialog = () => {
        setDeliveryDialogOpen(false);
        setDeliveryInfo(null);
    };

    const isStepFailed = (index, status) => {
        return status === 'Not Found'; // Returns true if step status is 'Not Found'
    };

    const handleAcceptOrder = async () => {
        const formData = new FormData();
        console.log('Selected Sales:', selectedSales);
        formData.append("invoice", selectedSales.invoice_sales);
        formData.append("delivery_number", formValues.kode_delivery || selectedSales.kode_delivery);  // Default to selectedSales track number
        formData.append("status", 'Telah Diterima');  // This will be the status sent to backend

        try {
            const response = await axios.post(
                `${apiUrl}api/sales_delivery/create`,
                formData,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                }
            );
            console.log('Order Accepted:', response.data);

            // Optionally close the dialog and refresh data
            setDeliveryDialogOpen(false);

            // Update the status locally as well
            setSales(prevSales => prevSales.map(sale =>
                sale.id_sale === selectedSales.id_sale ? { ...sale, status_ds: 'Telah Diterima' } : sale
            ));
        } catch (error) {
            console.error('Error accepting order:', error);
        }
    };

    const columns = [
        {
            name: 'No',
            selector: row => row.number,
            width: '50px',
            center: true,
        },
        {
            name: 'Invoice',
            cell: row => (
                <Chip label={row.invoice_sales || 'Not Found'} color="primary" variant="outlined" />
            ),
            sortable: true,
        },
        {
            name: 'Status Pembayaran',
            cell: row => {
                let chipColor;
                if (row.payment_status === 'settlement') {
                    chipColor = '#2072d8';
                } else if (row.payment_status === 'pending') {
                    chipColor = '#808080';
                } else {
                    chipColor = '#930a16';
                }
                return (
                    <Chip label={row.payment_status || 'Not Found'} sx={{ backgroundColor: chipColor, color: 'white' }} />
                );
            },
            sortable: true,
        },
        {
            name: 'Delivery Number',
            cell: row => (
                <Button
                    variant="contained"
                    color="success"
                    sx={{ borderRadius: 4 }}
                    onClick={() => {
                        console.log('Row data on delivery click:', row); // Debugging log
                        handleDeliveryClick(row);
                    }}
                >
                    {row.kode_delivery || 'Not Found'}
                </Button>
            ),
            sortable: true,
        },
        {
            name: 'Total',
            selector: row => formatPrice(row.final_total),
            sortable: true,
        },
        {
            name: 'Detail',
            cell: row => (
                <Button variant="outlined" color="primary" onClick={() => handleClickOpen(row)}>
                    Detail
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const salesWithNumbering = filteredSales.map((sale, index) => ({
        id: sale.id_sale,
        number: index + 1,
        ...sale,
    }));

    const formatPrice = (price) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
        }).format(price);
    };
    function getActiveStep(status) {
        const steps = [
            '',
            'Pesanan Diterima',
            'Prepare Packing',
            'Barang Diserahkan ke Ekspedisi',
            'Sedang Dalam Pengiriman',
            'Telah Diterima',
            ''
        ];
        const index = steps.indexOf(status);
        // Jika indeks lebih besar atau sama dengan 5, tambah 6
        if (index > 5) {
            return index + 6;
        } else {
            return index > 0 ? index : -1;
        }

        // Jika indeks ditemukan (lebih kecil dari 5), kembalikan nilai normal


    }
    console.log(getActiveStep());
    return (
        <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, paddingTop: '20px', backgroundColor: '#fafafa' }}>
            <Typography variant="h4" gutterBottom color="primary">
                Sales Orders
            </Typography>

            <TextField
                label="Search Invoice"
                variant="outlined"
                fullWidth
                sx={{ marginBottom: 3, backgroundColor: 'white', borderRadius: 1 }}
                value={searchInvoice}
                onChange={handleSearch}
            />

            <Box sx={{ height: 'calc(100vh - 300px)', overflowY: 'auto', mb: 2 }}>
                <DataTable
                    columns={columns}
                    data={salesWithNumbering}
                    pagination
                    highlightOnHover
                    striped
                    responsive
                    customStyles={{
                        rows: {
                            style: {
                                fontSize: '14px',
                            },
                        },
                        headCells: {
                            style: {
                                backgroundColor: '#f4f6f8',
                                fontWeight: 'bold',
                            },
                        },
                        cells: {
                            style: {
                                fontSize: '14px',
                            },
                        },
                    }}
                />
            </Box>
            {selectedSales && open && <InvoiceDetail selectedSales={selectedSales} handleCloseModalDialog={handleClose} />}

            <Dialog open={deliveryDialogOpen} onClose={handleCloseDeliveryDialog}>
                <DialogTitle>Delivery Progress ({deliveryInfo?.kodeDelivery || 'Not Available'})</DialogTitle>
                <DialogContent>
                    <Stepper activeStep={getActiveStep(deliveryInfo?.status)} alternativeLabel>
                        {['Pesanan Diterima', 'Prepare Packing', 'Barang Diserahkan ke Ekspedisi', 'Sedang Dalam Pengiriman', 'Telah Diterima'].map((label, index) => {
                            const labelProps = {};
                            if (isStepFailed(deliveryInfo?.status)) {
                                labelProps.optional = (
                                    <Typography variant="caption" color="error">
                                        <ErrorIcon sx={{ marginRight: 1 }} />
                                        Not Found
                                    </Typography>
                                );
                            }

                            return (
                                <Step key={index}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        }
                        )
                        }
                    </Stepper>

                    <Typography variant="body3" color="textSecondary">
                        Last Update: {deliveryInfo?.lastUpdate || 'Not Available'}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeliveryDialog} color="primary">
                        Close
                    </Button>
                    <Button onClick={handleAcceptOrder} color="warning" variant='outlined'>
                        Terima Pesanan
                    </Button>
                </DialogActions>
            </Dialog>

        </Paper>
    );


};
export default SalesDetail;
