// About.js
import React, { useEffect, useState } from 'react';
import { Container, Button,Typography,Card, CardMedia,CardContent,Grid } from '@mui/material';
const apiUrl = process.env.REACT_APP_API_URL; // Ganti dengan URL endpoint API Anda

const About = () => {
    const [aboutList, setAboutList] = useState([]);
  
    useEffect(() => {
      console.log('Fetching about list...');
      fetchAboutList();
    }, []);
  
    const fetchAboutList = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/about/`);
        const data = await response.json();
        console.log('Fetched about list:', data); // Log data untuk memeriksa
        setAboutList(data);
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };
  return (
    <Container sx={{ mt: 4 ,paddingBottom:'50px'}}>
      <Grid container spacing={4} sx={{ mt: 2 }}>
        {aboutList.map(item => (
          <Grid item key={item.id_about} xs={12} sm={12} md={12}>
            <Card>
              <CardMedia
                component="img"
                image={`${apiUrl}uploads/header/${item.photo_about}`}
                sx={{
                    maxWidth: '100%', // Batas maksimum lebar
                    maxHeight: '100%', // Batas maksimum tinggi, bisa disesuaika
                    borderRadius: '8px',
                    display: 'block',
                    objectFit: 'contain', // Menjaga rasio gambar
                    height:'400px',
                    mb: 2,
                    alignItems:'center'
                  }}
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                <span dangerouslySetInnerHTML={{ __html: item.text_about }} />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      </Container>
  );
};

export default About;
