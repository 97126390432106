import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  Box, Chip,
  TextField,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

const apiUrl = process.env.REACT_APP_API_URL; // Adjusted for common environment variable names

const Dashboard = () => {
  const [stock, setStock] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredStock, setFilteredStock] = useState([]);

  useEffect(() => {
    const fetchStock = async () => {
      try {
        const response = await fetch(`${apiUrl}api/stocklistinventory/`);
        const data = await response.json();
        setStock(data);
        setFilteredStock(data); // Initialize filtered stock with all stock
      } catch (error) {
        console.error('Error fetching stock:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStock();
  }, []);

  // Effect to filter stock based on the search term
  useEffect(() => {
    if (searchTerm) {
      const results = stock.filter(item =>
        item.brand.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.specifications.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredStock(results);
    } else {
      setFilteredStock(stock); // Use the original stock array if no search term
    }
  }, [searchTerm, stock]);

  if (loading) return <div>Loading...</div>;
  const stripHtmlAndTruncate = (htmlContent, maxLength) => {
    const plainText = htmlContent.replace(/<[^>]*>?/gm, ''); // Hapus tag HTML
    return plainText.length > maxLength ? `${plainText.slice(0, maxLength)}...` : plainText;
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const formatPrice = (price) => {
    return new Intl.NumberFormat('id-Id', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0, // Mengatur jumlah desimal
    }).format(price);
  };
  return (
    <Container sx={{ mt: 4, paddingBottom: 8 }}>
      <Box mb={6}>
        <CardMedia
          component="img"
          height="450"
          image={`${apiUrl}uploads/header/header.png`} // Ganti dengan URL gambar banner Anda
          alt="Banner"
          sx={{
            borderRadius: '10px',
            objectFit: 'fill',
            width: '100%',
            mb: 2,
          }}
        />
      </Box>
      <Grid container spacing={4}>
        {/* Search Field */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Search..."
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ mb: 3 }}
          />
        </Grid>

        {/* Products Section */}
        {filteredStock.map((item) => (
          <Grid item key={item.id_sli} xs={12} sm={6} md={3} >
            <Link to={`/purchase/${item.id_sli}`} style={{ textDecoration: 'none' }}>
              <Card
                sx={{
                  transition: 'transform 0.8s',
                  borderRadius: '19px',
                  '&:hover': {
                    transform: 'scale(1.05)', // Slightly reduced scale for a subtle effect
                  },
                }}
              >
                {/* Slider for Photos */}

                <Box>
                  {item.photo_brand.length > 1 ? (
                    <Slider {...settings}>
                      {item.photo_brand.map((photo) => (
                        <div key={photo.id_photo}>
                          <CardMedia
                            component="img"
                            height="200"
                            image={`${apiUrl}/uploads/brand_photos/${photo.photo_url}`}
                            alt={item.brand}
                            sx={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                              display: 'block',
                              objectFit: 'contain',
                              borderTopLeftRadius: '8px',
                              borderTopRightRadius: '8px',
                            }}
                          />
                        </div>
                      ))}
                    </Slider>
                  ) : item.photo_brand.length === 1 ? (
                    <CardMedia
                      component="img"
                      height="200"
                      image={`${apiUrl}/uploads/brand_photos/${item.photo_brand[0].photo_url}`}
                      alt={item.brand}
                      sx={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        display: 'block',
                        objectFit: 'contain',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                      }}
                    />
                  ) : (
                    <CardMedia
                      component="img"
                      height="200"
                      image="placeholder-image-url" // Placeholder when no image
                      alt={item.brand}
                      sx={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        display: 'block',
                        objectFit: 'contain',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                      }}
                    />
                  )}
                </Box>


                <CardContent sx={{ backgroundColor: "#f8f4ff", padding: 2, borderRadius: 1 }}>
                  <Typography variant="h7" component="div" gutterBottom sx={{ color: "#000000", fontFamily: 'calibri' }}  >
                    {stripHtmlAndTruncate(item.brand, 80)}
                  </Typography>

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    noWrap sx={{ color: "#6a5acd" }}
                  >
                    {stripHtmlAndTruncate(item.specifications, 80)}
                  </Typography>

                  <Box sx={{ mt: 1 }}>
                    {/* <Typography variant="h5" color="text.primary" sx={{ color: "#4b0082" }}>
                      {formatPrice(item.unit_price)}
                    </Typography> */}

                    <Chip label={formatPrice(item.unit_price)} color="warning" variant="outlined"
                      sx={{ width: '300px', height: '30px', fontSize: '18px', }}
                    ></Chip>

                    {/* <Typography variant="body2" color="text.secondary" sx={{ color: "#8a2be2" }}>
      Stock: {item.stock_quantity}
    </Typography> */}
                  </Box>
                </CardContent>

                <CardActions>

                  {/* <Button size="small" variant="contained" color="primary">
                    Buy Now
                  </Button>
                */}
                </CardActions>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Dashboard;
