import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip } from '@mui/material';
import { jsPDF } from 'jspdf';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const InvoiceDetail = ({ selectedSales, handleCloseModalDialog }) => {
    const [openModalDialog, setOpenModalDialog] = useState(true);
    // Format harga ke IDR
    const formatPrice = (price) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
        }).format(price);
    };

    // const handleClose = () => {
    //     setOpenModalDialog(false); // Tutup modal
    //     handleCloseModalDialog(); // Panggil fungsi dari induk untuk menginformasikan modal telah ditutup
    // };

    const user = JSON.parse(localStorage.getItem('user'));
    const fullname = user.fullname;
    const navigate = useNavigate();

    // Fungsi untuk membuka pop-up Snap Midtrans
    const CheckStatusPayment = async () => {
        const snapToken = selectedSales.payment_id; // Snap Token yang sudah ada di selectedSales
        if (snapToken) {
            // Memanggil Snap Midtrans untuk membuka pop-up pembayaran
            window.snap.pay(snapToken, {
                onSuccess: async function (result) {
                    console.log('Payment success:', result);
                    alert('Payment Successful');
                    const order_id = result.order_id; // Tidak perlu menggunakan JSON.parse
                    console.log('order_id:', order_id);

                    // Melakukan panggilan ke API menggunakan axios
                    try {
                        const paymentResponse = await axios.get(`${apiUrl}api/payment`, {
                            params: {
                                order_id: order_id,
                                status_code: 200,
                                transaction_status: 'settlement'
                            }
                        });

                        // Tangani respons dari API di sini
                        console.log('Payment API response:', paymentResponse.data);
                        alert('Payment processed successfully!');
                    } catch (paymentError) {
                        if (paymentError.response && paymentError.response.status === 409) {
                            console.error('Error 409: Conflict - Status transaksi mungkin sudah diperbarui');
                            alert('Error: Transaksi sudah diproses atau statusnya berubah.');
                        } else {
                            console.error('Error while hitting payment callback:', paymentError);
                            alert('Error while processing payment callback.');
                        }
                    }
                },
                onPending: function (result) {
                    console.log('Payment pending:', result);
                    alert('Payment is pending');
                },
                onError: function (result) {
                    console.log('Payment error:', result);
                    try {
                        const paymentResponse = axios.get(`${apiUrl}api/payment`, {
                            params: {
                                order_id: selectedSales.id,
                                status_code: 200,
                                transaction_status: 'failed'
                            }
                        });
                        console.log('Payment API response:', paymentResponse.data);
                        alert('Payment failed');
                        navigate('/salesdetail')
                    } catch {
                        alert('Payment failed');
                    }
                },
                onClose: function () {
                    console.log('Payment window closed');
                    alert('Payment window closed');
                },
            });
        } else {
            console.error('Snap token not available');
            alert('Error: Snap token is missing');
        }
    };



    const downloadInvoicePDF = () => {
        const doc = new jsPDF();
        let yPosition = 20; // Margin atas

        // Header
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(18);
        doc.text('CV Kausar', 105, yPosition, null, null, 'center');
        yPosition += 10;

        doc.setFont('helvetica', 'normal');
        doc.setFontSize(12);
        doc.text(`Invoice #: ${selectedSales.invoice_sales}`, 10, yPosition);
        doc.text(`Date: ${selectedSales.transaction_date}`, 200, yPosition, null, null, 'right');
        yPosition += 8;

        // Customer Information
        doc.setFont('helvetica', 'bold');
        doc.text('Customer Details', 10, yPosition);
        yPosition += 6;

        doc.setFont('helvetica', 'normal');
        doc.text(`Customer Name: ${fullname}`, 10, yPosition);
        yPosition += 6;
        doc.text(`Delivery Number: ${selectedSales.kode_delivery ? selectedSales.kode_delivery.toUpperCase() : 'Not Found'} (${selectedSales.jasa_ekspedisi.toUpperCase()} - ${formatPrice(selectedSales.ongkir_sales)})`, 10, yPosition);
        yPosition += 6;

        doc.text(`Payment Status: ${selectedSales.payment_status.toUpperCase()}`, 10, yPosition);
        doc.text(`Payment Method: ${selectedSales.payment_method === '' ? 'Not Found' : selectedSales.payment_method.toUpperCase()}`, 200, yPosition, null, null, 'right');
        yPosition += 10;

        // Table Header
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(12);
        doc.text('Product', 10, yPosition);
        doc.text('Qty', 100, yPosition, null, null, 'center');
        doc.text('Unit Price', 130, yPosition, null, null, 'center');
        doc.text('Total', 170, yPosition, null, null, 'center');
        yPosition += 6;

        doc.setLineWidth(0.1);
        doc.line(10, yPosition, 200, yPosition);
        yPosition += 4;

        // Table Rows
        doc.setFont('helvetica', 'normal');
        const maxWidth = 80; // Lebar maksimal untuk kolom "Product"

        selectedSales.sales_items.forEach((item) => {
            const wrappedText = doc.splitTextToSize(item.brand, maxWidth);
            wrappedText.forEach((line, index) => {
                doc.text(line, 10, yPosition + (index * 4)); // Menambahkan jarak antar baris
            });
            doc.text(item.quantity.toString(), 100, yPosition, null, null, 'center');
            doc.text(formatPrice(item.unit_price), 130, yPosition, null, null, 'center');
            doc.text(formatPrice(item.total_price), 170, yPosition, null, null, 'center');
            yPosition += 15;
        });

        doc.line(10, yPosition, 200, yPosition);
        yPosition += 30;

        // Total Section
        doc.setFont('helvetica', 'bold');
        doc.text(`Delivery : ${formatPrice(selectedSales.ongkir_sales)}`, 10, yPosition - 10);
        doc.setFont('helvetica', 'bold');
        doc.text(`Total Price: ${formatPrice(selectedSales.final_total)}`, 10, yPosition);
        yPosition += 10;

        // Footer
        doc.setFont('helvetica', 'italic');
        doc.setFontSize(10);
        doc.text('Thank you for shopping with us!', 105, 280, null, null, 'center');
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const currentDate = month + "-" + date + "-" + year;
        // Save PDF
        doc.save(`${selectedSales.invoice_sales}-${currentDate}.pdf`);
    };
    console.log(selectedSales);
    return (
        <Dialog open={true} onClose={handleCloseModalDialog} fullWidth maxWidth="lg">
            <DialogTitle>Invoice #{selectedSales.invoice_sales}</DialogTitle>
            <DialogContent>
                <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', color: '#333' }}>
                    <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                        <Typography variant="h6" gutterBottom>
                            <strong>Invoice Detail</strong>
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1"><strong>Customer Name :</strong> {fullname}</Typography>
                                <Typography variant="body1"><strong>Invoice Date :</strong> {selectedSales.transaction_date}</Typography>
                                <Typography variant="body1"><strong>Delivery Number :</strong><Chip label={selectedSales.kode_delivery ? selectedSales.kode_delivery.toUpperCase() : 'Not Found'} sx={{ backgroundColor: '#00d21b', fontWeight: 'bold' }} /> {selectedSales.kode_delivery} ( {selectedSales.jasa_ekspedisi.toUpperCase()} - {formatPrice(selectedSales.ongkir_sales)} )</Typography>
                                <Typography variant="body1"><strong>Status :</strong> <Chip label={selectedSales.payment_status.toUpperCase()} sx={{ backgroundColor: selectedSales.payment_status === 'settlement' ? '#2072d8' : (selectedSales.payment_status === 'pending' ? '#808080' : '#930a16'), color: 'white' }} /></Typography>
                                <Typography variant="body1"><strong>Method Payment:</strong> {selectedSales.payment_method == '' ? 'Not Found' : selectedSales.payment_method.toUpperCase()}</Typography>

                            </Grid>
                            <Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
                                <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                                    {formatPrice(selectedSales.final_total)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>

                    <Typography variant="h6" gutterBottom>
                        Products
                    </Typography>
                    <TableContainer component={Paper} elevation={3}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <strong>Product</strong>
                                    </TableCell>
                                    <TableCell align="center">
                                        <strong>Qty</strong>
                                    </TableCell>
                                    <TableCell align="center">
                                        <strong>Unit Price</strong>
                                    </TableCell>
                                    <TableCell align="center">
                                        <strong>Total</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedSales.sales_items.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.brand}</TableCell>
                                        <TableCell align="center">{item.quantity}</TableCell>
                                        <TableCell align="center">{formatPrice(item.unit_price)}</TableCell>
                                        <TableCell align="center">{formatPrice(item.total_price)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={CheckStatusPayment} color="primary">
                    Payment
                </Button>
                <Button onClick={downloadInvoicePDF} color="primary">
                    Download PDF
                </Button>
                <Button onClick={handleCloseModalDialog} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InvoiceDetail;