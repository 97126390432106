// src/api/api.js
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL; // Pastikan URL backend Anda sudah terkonfigurasi

export const getUsers = async () => {
  const response = await fetch(`${apiUrl}/users`);
  return response.json();
};

export const getAdmins = async () => {
  const response = await fetch(`${apiUrl}/admins`);
  return response.json();
};
export const addUser = async (userData) => {
  const response = await fetch(`${apiUrl}/users/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),
  });
  return response.json();
};

export const loginUser = async (credentials) => {
  const response = await fetch(`${apiUrl}/users/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  });
  return response.json();
};

export const loginAdmin = async (credentials) => {
  const response = await fetch(`${apiUrl}/admins/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  });
  return response.json();
};
export const getPpnList = () => axios.get(`${apiUrl}api/ppn`);
export const getPpnById = (id) => axios.get(`${apiUrl}api/ppn/${id}`);
export const createPpn = (data) => axios.post(`${apiUrl}api/ppn/create`, data);
export const updatePpn = (id, data) => axios.put(`${apiUrl}api/ppn/update/${id}`, data);
export const deletePpn = (id) => axios.delete(`${apiUrl}api/ppn/delete/${id}`);