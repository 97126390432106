import React, { useState, useEffect } from 'react';
import { Paper, Typography, Button } from '@mui/material';
import InventoryList from './InventoryList';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const InventoryManagement = () => {
  const [inventory, setInventory] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadInventory();
  }, []);

  const loadInventory = async () => {
    const response = await fetch(`${apiUrl}api/stocklistinventory`);
    const items = await response.json();
    setInventory(items);
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    }).format(price);
  };

  return (
    <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, backgroundColor: '#fafafa' }}>
      <Typography variant="h4" gutterBottom>
        Manage Inventory
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/admin/inventory/add')}
        sx={{ mb: 2 }}
      >
        Add Data
      </Button>
      <InventoryList inventory={inventory} formatPrice={formatPrice} />
    </Paper>
  );
};

export default InventoryManagement;
