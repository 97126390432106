// src/Header.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, CardMedia, Box, Menu, MenuItem, Dialog, DialogTitle, Badge, DialogContent } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Shop2TwoToneIcon from '@mui/icons-material/Shop2TwoTone';
const apiUrl = process.env.REACT_APP_API_URL;

const Header = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const admin = JSON.parse(localStorage.getItem('admin'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('user'); // Menghapus data user dari localStorage
    localStorage.removeItem('admin');
    localStorage.removeItem('cart');
    handleMenuClose();
    window.location.reload();
    navigate('/login'); // Mengarahkan ke halaman login
  };

  const popUpProfile = () => {
    setOpenProfileDialog(true); // Membuka dialog profil
  };

  const handleCloseProfileDialog = () => {
    setOpenProfileDialog(false); // Menutup dialog profil
  };

  const handleLogin = () => {
    handleMenuClose();
    navigate('/login');
  };

  const storedCarts = JSON.parse(localStorage.getItem('cart')) || [];
  const totalQuantity = storedCarts.reduce((total, item) => total + (item.quantity || 0), 0);

  return (
    <AppBar position="sticky" sx={{ backgroundColor: '#180161', boxShadow: 3 }}>
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>

          <IconButton color="inherit" onClick={() => navigate('/')} aria-label="home">
            <Shop2TwoToneIcon style={{ width: 40, height: 50, marginRight: 8 }} />
          </IconButton>
          CV Kausar</Typography>


        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
          {/* Ikon Keranjang */}
          <Button variant="contained" onClick={() => navigate('/')} aria-label='About'>Beranda</Button>
          <Button variant="contained" onClick={() => navigate('/about')} aria-label='About'>Tentang</Button>

          <IconButton color="inherit" onClick={() => navigate('/cart')} aria-label="keranjang">
            <Badge badgeContent={totalQuantity} color="warning">
              <ShoppingCartIcon />
            </Badge>
          </IconButton>


          {/* Menu Dropdown Login/Logout */}
          <IconButton color="inherit" onClick={handleMenuOpen} aria-label="akun">
            <AccountCircleIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {user ? (
              <Box>
                <MenuItem onClick={popUpProfile}>{user.fullname}</MenuItem>

                {admin ? (
                  // Menu khusus admin
                  <>
                    <MenuItem onClick={() => navigate('/admin/inventory')}>Inventory</MenuItem>
                    <MenuItem onClick={() => navigate('/admin/delivery')}>Delivery</MenuItem>
                  </>
                ) : (
                  // Menu untuk user biasa
                  <MenuItem onClick={() => navigate('/salesdetail')}>Pesanan</MenuItem>
                )}

                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Box>
            ) : (
              <Box>
                <MenuItem onClick={handleLogin}>Login</MenuItem>
              </Box>
            )}

          </Menu>

          {/* Tombol Home di ujung kanan */}
          <IconButton
            color="inherit"
            component={Link}
            to="/"
            aria-label="home"
            sx={{
              width: '55px',
              border: '3px solid white',
              borderRadius: '18px',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
              },
            }}
          >
            <HomeIcon />
          </IconButton>
        </Box>

        {/* Dialog Profil */}
        <Dialog open={openProfileDialog} onClose={handleCloseProfileDialog}>
          <DialogTitle>Profil Pengguna</DialogTitle>
          <DialogContent>
            {user ? (
              <Box>
                <CardMedia
                  component="img"
                  height="200"
                  image={`${apiUrl}/uploads/user_photos/${user.profile_photo}`}// Update this line
                  sx={{
                    maxWidth: '100%', // Batas maksimum lebar
                    maxHeight: '100%', // Batas maksimum tinggi, bisa disesuaikan
                    display: 'block',
                    objectFit: 'contain', // Menjaga rasio gambar
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                  }}
                />
                <Typography variant="h8">Akun : {user.fullname}</Typography>
                <Typography variant="body1">Email : {user.email}</Typography>
                {console.log(user)}
              </Box>
            ) : (
              <Typography>Data profil tidak ditemukan.</Typography>
            )}
          </DialogContent>
        </Dialog>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
