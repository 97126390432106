import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Delete as DeleteIcon } from '@mui/icons-material';
import ReactQuill from 'react-quill';
const apiUrl = process.env.REACT_APP_API_URL;
const MAX_FILES = 10; // Maksimal file untuk foto atau dokumen

const InventoryForm = ({ userId = 6 }) => {
  // State Management
  const [brand, setBrand] = useState('');
  const [specifications, setSpecifications] = useState('');
  const [weight, setWeight] = useState('');
  const [dimensi, setDimensi] = useState('');
  const [stockQuantity, setStockQuantity] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [totalValue, setTotalValue] = useState('');
  const [stockTypes, setStockTypes] = useState([{ id: 0, value: '', price: '' }]);
  const [fileInputs, setFileInputs] = useState([{ id: 0, file: null, preview: null }]);
  const [docInputs, setDocInputs] = useState([{ id: 0, file: null }]);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

  const navigate = useNavigate();

  // Hitung nilai total saat jumlah stok atau harga satuan berubah
  useEffect(() => {
    const calculateTotalValue = () => {
      const quantity = parseFloat(stockQuantity) || 0;
      const price = parseFloat(unitPrice) || 0;
      setTotalValue(quantity * price);
    };
    calculateTotalValue();
  }, [stockQuantity, unitPrice]);

  // Handle perubahan pada input jenis stok
  const handleStockTypeChange = (id, field, value) => {
    setStockTypes((prev) =>
      prev.map((type) => (type.id === id ? { ...type, [field]: value } : type))
    );
  };

  // Tambahkan baris baru untuk jenis stok
  const addStockType = () => {
    setStockTypes([...stockTypes, { id: stockTypes.length, value: '', price: '' }]);
  };

  // Hapus jenis stok berdasarkan ID
  const deleteStockType = (id) => {
    setStockTypes(stockTypes.filter((type) => type.id !== id));
  };

  // Tambahkan input untuk file foto
  const addFileInput = () => {
    if (fileInputs.length < MAX_FILES) {
      setFileInputs([...fileInputs, { id: fileInputs.length, file: null, preview: null }]);
    } else {
      setSnackbar({ open: true, message: 'Maksimal 10 foto tercapai.', severity: 'warning' });
    }
  };

  // Tangani perubahan file foto
  const handleFileChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      setFileInputs((prev) =>
        prev.map((input, idx) =>
          idx === index ? { ...input, file, preview: URL.createObjectURL(file) } : input
        )
      );
    }
  };

  // Tambahkan input untuk dokumen
  const addDocInput = () => {
    if (docInputs.length < MAX_FILES) {
      setDocInputs([...docInputs, { id: docInputs.length, file: null }]);
    } else {
      setSnackbar({ open: true, message: 'Maksimal 10 dokumen tercapai.', severity: 'warning' });
    }
  };

  // Tangani perubahan file dokumen
  const handleDocChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      setDocInputs((prev) =>
        prev.map((input, idx) => (idx === index ? { ...input, file } : input))
      );
    }
  };

  // Handle pengiriman data
  const handleAddInventory = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('id_user_admin', userId);
    formData.append('brand', brand);
    formData.append('specifications', specifications);
    formData.append('dimensi', dimensi);
    formData.append('weight', weight);
    formData.append('stock_quantity', stockQuantity);
    formData.append('unit_price', unitPrice);
    formData.append('total_value', totalValue);

    stockTypes.forEach((type) => {
      formData.append('stock_type[]', type.value);
      formData.append('price_type[]', type.price);
    });

    fileInputs.forEach((input) => {
      if (input.file) formData.append('brand_photo[]', input.file);
    });

    docInputs.forEach((input) => {
      if (input.file) formData.append('docs[]', input.file);
    });

    try {
      const response = await fetch(`${apiUrl}/api/stocklistinventory/create`, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      setSnackbar({ open: true, message: result.message, severity: 'success' });
      setLoading(false);

      // Tambahkan delay sebelum navigasi
      setTimeout(() => {
        navigate('/');
      }, 1000);
    } catch (error) {
      console.error('Error:', error);
      setSnackbar({ open: true, message: 'Terjadi kesalahan saat menambahkan inventory.', severity: 'error' });
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ paddingBottom: '50px', paddingTop: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Tambah Barang Baru
      </Typography>
      <form onSubmit={handleAddInventory}>
        {/* Form Input Fields */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Barang"
              variant="outlined"
              fullWidth
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              label="Dimensi (PxLxT)"
              variant="outlined"
              fullWidth
              value={dimensi}
              onChange={(e) => setDimensi(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Berat (Kg)"
              variant="outlined"
              fullWidth
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={18} sm={12}>
            <Typography>
              Spesifikasi
            </Typography>
            <ReactQuill
              theme="snow"
              label="Spesifikasi"
              value={specifications}
              onChange={(value) => setSpecifications(value)} // Pastikan menerima string value langsung
              required
            />
            {/* <TextField
              label="Spesifikasi"
              variant="outlined"
              fullWidth
              value={specifications}
              onChange={(e) => setSpecifications(e.target.value)}
              required
            /> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Stok Qty"
              variant="outlined"
              fullWidth
              type="number"
              value={stockQuantity}
              onChange={(e) => setStockQuantity(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Harga Satuan"
              variant="outlined"
              fullWidth
              type="number"
              value={unitPrice}
              onChange={(e) => setUnitPrice(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Harga Total"
              variant="outlined"
              fullWidth
              type="number"
              value={totalValue}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          Tipe Stok
        </Typography>
        <Grid container spacing={2}>
          {stockTypes.map((type, index) => (
            <React.Fragment key={type.id}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={`Tipe Barang ${index + 1}`}
                  variant="outlined"
                  fullWidth
                  value={type.value}
                  onChange={(e) => handleStockTypeChange(type.id, 'value', e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={`Tambahan Harga ${index + 1}`}
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={type.price}
                  onChange={(e) => handleStockTypeChange(type.id, 'price', e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <IconButton
                  color="error"
                  onClick={() => deleteStockType(type.id)}
                  disabled={stockTypes.length === 1}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
        <Button variant="contained" color="secondary" onClick={addStockType} sx={{ mt: 2 }}>
          Tambah Tipe Barang
        </Button>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          Upload Gambar
        </Typography>
        {fileInputs.map((input, index) => (
          <div key={input.id} style={{ marginBottom: '20px' }}>
            <input
              type="file"
              className="form-control-file"
              onChange={(e) => handleFileChange(index, e)}
            />
            {input.preview && (
              <div style={{ marginTop: '10px' }}>
                <img src={input.preview} alt="Preview" width="100" />
              </div>
            )}
          </div>
        ))}
        <Button variant="contained" color="secondary" onClick={addFileInput} sx={{ mt: 2 }}>
          Tambah Gambar
        </Button>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          Upload Dokumen
        </Typography>
        {docInputs.map((input, index) => (
          <div key={input.id} style={{ marginBottom: '20px' }}>
            <input
              type="file"
              className="form-control-file"
              onChange={(e) => handleDocChange(index, e)}
            />
          </div>
        ))}
        <Button variant="contained" color="secondary" onClick={addDocInput} sx={{ mt: 2 }}>
          Tambah Dokumen
        </Button>
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 4 }} disabled={loading}>
          Simpan
        </Button>
      </form>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default InventoryForm;
