import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  Container,
  Paper,
  Grid
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import navigate from "navigate";

const apiUrl = process.env.REACT_APP_API_URL; // Adjusted for common environment variable names
const CreateUsers = () => {
  const [formData, setFormData] = useState({
    fullname: "",
    username: "",
    password: "",
    email: "",
    phone_number: "",
    address: "",
    gender: "",
    birthdate: dayjs(),  // Inisialisasi dengan dayjs
    profile_photo: null,
    is_active: true,
    is_verified: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      birthdate: date ? dayjs(date).format("YYYY-MM-DD") : null,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      profile_photo: e.target.files[0], // Ganti `profile_photo` menjadi `photo`
    });
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
  
    // Tambahkan data teks
    for (const key in formData) {
      if (key !== 'profile_photo') {  // Tambahkan pengecualian untuk file foto
        formDataToSend.append(key, formData[key]);
      }
    }
  
    // Tambahkan data file (foto)
    if (formData.profile_photo) {
      formDataToSend.append("photo", formData.profile_photo);
      
    }
  
    try {
      const response = await fetch(`${apiUrl}api/users/create`, {
        method: "POST",
        body: formDataToSend,
      });
      const result = await response.json();
      alert(result.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{paddingBottom:"200px"}}>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
        <Typography variant="h5" gutterBottom>
          Tambah Pengguna Baru
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Full Name"
                name="fullname"
                value={formData.fullname}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Username"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleInputChange}
                  autoComplete="off"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                autoComplete="off"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone Number"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
            <FormControl fullWidth>
                    <InputLabel>Gender</InputLabel>
                    <Select
                    label="Gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    </Select>
                    </FormControl>
</Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Birthdate"
                  value={dayjs(formData.birthdate)}  // Perbarui value dengan dayjs
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <input
                type="file"
                  className="form-control-file"
                name="profile_photo"
                accept="image/*"
                onChange={handleFileChange}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.is_active}
                    onChange={handleSwitchChange}
                    name="is_active"
                  />
                }
                label="Active"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.is_verified}
                    onChange={handleSwitchChange}
                    name="is_verified"
                  />
                }
                label="Verified"
              />
            </Grid> */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        <Typography sx={{paddingTop:'10px'}}>Sudah punya akun?  <Button variant='outlined' onClick={{navigate:'/login'}}>Klik Disini.</Button></Typography>
      </Paper>
    </Container>
  );
};

export default CreateUsers;
