import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
const AddPhotoForm = () => {
  const [sliOptions, setSliOptions] = useState([]);
  const [selectedSli, setSelectedSli] = useState("");
  const [photoFile, setPhotoFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const admin = JSON.parse(localStorage.getItem("admin"));
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  // Fetch SLI options on component mount
  useEffect(() => {
    const fetchSliOptions = async () => {
      try {
        const response = await axios.get(`${apiUrl}api/form_select_sli`);
        setSliOptions(response.data.data); // Assuming API returns { data: [ { id_sli, sli_name } ] }
      } catch (error) {
        console.error("Error fetching SLI options:", error);
        setSnackbar({ open: true, message: "Failed to load product options", severity: "error" });
      }
    };

    fetchSliOptions();
  }, [apiUrl]);

  const handleFileChange = (e) => {
    setPhotoFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!selectedSli || !photoFile) {
      setSnackbar({ open: true, message: "Please select a product and upload a Photo", severity: "warning" });
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("id_sli", selectedSli);
    formData.append("photo_url", photoFile);
    formData.append("uploaded_by", admin?.id_users_admin); // Use actual user ID if provided

    try {
      const response = await axios.post(`${apiUrl}api/photo/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setSnackbar({ open: true, message: "Photo prodcut uploaded successfully!", severity: "success" });
      setTimeout(() => {
        navigate("/admin/photo"); // Navigasi setelah 3 detik
      }, 1500);
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to upload Photo product", severity: "error" });
      console.error("Error uploading photo:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: "", severity: "success" });
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 4,
        maxWidth: 500,
        margin: "auto",
        marginTop: 4,
        marginBottom: 10,
        borderRadius: 4,
        backgroundColor: "#fdfdfd",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Typography variant="h5" gutterBottom textAlign="center" sx={{ fontWeight: 600, color: "#1976d2" }}>
        Upload New Document
      </Typography>

      <form onSubmit={handleSubmit}>
        <Box mb={3}>
          <Typography variant="subtitle1" gutterBottom>
            Select Product
          </Typography>
          <Select
            value={selectedSli}
            onChange={(e) => setSelectedSli(e.target.value)}
            fullWidth
            displayEmpty
            sx={{ backgroundColor: "#fff", borderRadius: 1 }}
          >
            <MenuItem value="" disabled>
              Select a Product
            </MenuItem>
            {sliOptions.map((option) => (
              <MenuItem key={option.id_sli} value={option.id_sli}>
                {option.brand}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box mb={3}>
          <Typography variant="subtitle1" gutterBottom>
            Upload Photo Product (PNG/JPG/TIF/JPEG) Max 2Mb
          </Typography>
          <TextField
            type="file"
            inputProps={{
              accept: ".png,.jpg,.jpeg,.tif",
            }}
            fullWidth
            sx={{ backgroundColor: "#fff", borderRadius: 1 }}
            onChange={handleFileChange}
          />
        </Box>

        <Box display="flex" justifyContent="center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={loading}
            sx={{ textTransform: "none", fontWeight: 600 }}
          >
            {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Save"}
          </Button>
        </Box>
      </form>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default AddPhotoForm;
