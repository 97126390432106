// src/components/Login.js
import React, { useState } from 'react';
import { loginAdmin } from '../apis/Api';
import { Container, TextField, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Hook for navigation

  const handleSubmit = async (e) => {
    e.preventDefault();
    const credentials = { email: loginId, password };
    const response = await loginAdmin(credentials);
    
    if (response.message === 'Login successful') {
      localStorage.setItem('admin', JSON.stringify(response.user));
      window.location.reload();
      navigate('/admin/inventory/');
    } else {
      alert(response.message);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email or Username"
          value={loginId}
          onChange={(e) => setLoginId(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Login
        </Button>
      </form>
    </Container>
  );
};

export default Login;
