import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Box,
  Button,
  useMediaQuery,
  Dialog,
  DialogContent,
  Snackbar,
  Chip,
  Avatar,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

const apiUrl = process.env.REACT_APP_API_URL;

const Purchase = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [productType, setProductType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [selectedStockType, setSelectedStockType] = useState(null);
  const [selectedStockTypeProduct, setSelectedStockTypeProduct] = useState(null);

  useEffect(() => {

    const fetchProduct = async () => {
      try {
        const response = await fetch(`${apiUrl}api/stocklistinventory/${id}`);
        const data = await response.json();
        setProduct(data);
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleAddToCart = () => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    const existingProduct = cart.find(item => item.id_sli === product.id_sli);
    if (existingProduct || !selectedStockTypeProduct) {
      // existingProduct.quantity += 1;
      setSnackbarOpen(true);
    } else {
      cart.push({
        id_sli: product.id_sli,
        brand: product.brand,
        unit_price: calculateTotalPrice(),
        quantity: 1,
        dimensi: product.dimensi,
        weight: product.weight,
        specifications: product.specifications,
        type_product: stockTypeProducts(),
        photo_brand: product.photo_brand,
        stock_quantity: product.stock_quantity,
        date_added: product.date_added,
      });
      localStorage.setItem('cart', JSON.stringify(cart));
      setSnackbarOpen(true);
      navigate('/cart');
    }


  };
  const calculateTotalPrice = () => {
    const stockTypePrice = selectedStockType ? parseFloat(selectedStockType.unit_price_type_sli) : 0;
    const basePrice = parseFloat(product.unit_price);
    return basePrice + stockTypePrice;
  };
  const stockTypeProducts = () => {
    const StockTypeProduct = selectedStockTypeProduct ? selectedStockTypeProduct.name_type_sli : '';
    return StockTypeProduct;
  }

  const handleSnackbarClose = () => setSnackbarOpen(false);

  if (loading) return <div>Loading...</div>;
  if (!product) return <div>Product not found</div>;

  const formatPrice = (price) =>
    new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    }).format(price);

  const settings = {
    dots: true,
    infinite: product?.photo_brand?.length > 1, // Only loop if more than one image
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentImageIndex,
  };
  const vertical = 'top';
  const horizontal = 'center';
  return (
    <Container sx={{ mt: 8, paddingBottom: 10 }}>
      <Box>
        <Card>
          <Box sx={{ p: 2, display: 'flex', gap: 2 }}>
            <Button variant="contained" color="primary"
              sx={{
                textTransform: 'none',
                mb: 1,
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                '&:not(:last-child)': { mr: 1 },
              }}
              onClick={() => navigate(-1)}>
              <SettingsBackupRestoreIcon />
              Back
            </Button>
            <Button variant="contained" color="warning"
              sx={{
                textTransform: 'none',
                mb: 1,
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                '&:not(:last-child)': { mr: 1 },
              }} onClick={handleAddToCart}>
              <AddShoppingCartIcon />
              Add
            </Button>
          </Box>

          <Slider {...settings}>
            {Array.isArray(product.photo_brand) && product.photo_brand.length > 0 ? (
              product.photo_brand.map((photo, index) => (
                <div key={photo.id_photo} onClick={() => handleImageClick(index)} style={{ cursor: 'pointer' }}>
                  <CardMedia
                    component="img"
                    height="400"
                    image={`${apiUrl}/uploads/brand_photos/${photo.photo_url}`}
                    alt={product.brand}
                    sx={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      display: 'block',
                      objectFit: 'contain',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px',
                    }}
                  />
                </div>
              ))
            ) : (
              <CardMedia
                component="img"
                image="placeholder-image-url"
                alt={product.brand}
                sx={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  display: 'block',
                  objectFit: 'contain',
                  margin: '0 auto',
                }}
              />
            )}
          </Slider>



          <CardContent sx={{ p: 4 }}>
            {/* Nama Produk */}
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1, color: '#fffff' }}>
              {product.brand}
            </Typography>

            {/* Spesifikasi Produk */}
            <Typography
              variant="body1"
              sx={{ mb: 2, lineHeight: 1.8, color: '#555' }}
              dangerouslySetInnerHTML={{ __html: product.specifications }}
            />

            {/* Harga Produk */}
            <Chip label={formatPrice(calculateTotalPrice())} color="warning" variant="outlined"
              sx={{ width: '250px', height: '50px', fontSize: '28px', }}
            >

            </Chip>
            <Typography sx={{ paddingTop: '20px' }}>
              Choose Stock Type :  <select
                value={selectedStockType ? selectedStockType.id_type_sli : ""}
                onChange={(e) => {
                  const selectedType = product.stock_type.find(
                    (type) => type.id_type_sli === e.target.value
                  );

                  setSelectedStockType(selectedType);
                  setSelectedStockTypeProduct(selectedType);
                }}
                style={{
                  textTransform: 'none', padding: "11px", mb: 1,
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
              >

                <option value="" disabled>
                  Choose Type
                </option>
                {product.stock_type.map((type) => (
                  <option key={type.id_type_sli} value={type.id_type_sli}>
                    {type.name_type_sli}
                  </option>
                ))}
              </select>
            </Typography>


            {/* Tanggal Ditambahkan */}
            <Typography
              variant="caption"
              sx={{
                display: 'block',
                mb: 3,
                color: '#999',
              }}
            >
              Ditambahkan pada: {new Date(product.date_added).toLocaleDateString()}
            </Typography>

            {/* Tombol Download PDF */}
            {product.doc_brand?.length > 0 ? (
              product.doc_brand.map((doc, index) => (
                <Button
                  key={index}
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: 'none',
                    mb: 1,
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    '&:not(:last-child)': { mr: 1 },
                  }}
                  target="_blank"
                  href={`${apiUrl}uploads/docs/${doc.document_url}`}
                ><PictureAsPdfIcon />
                  Download
                </Button>
              ))
            ) : (
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  textTransform: 'none',
                  mb: 1,
                  borderColor: '#f44336',
                  color: '#f44336',
                  '&:hover': {
                    backgroundColor: '#f44336',
                    color: '#fff',
                  },
                }}
                disabled
              ><PictureAsPdfIcon />
                Download
              </Button>
            )}
            <Button variant="contained" color="warning"
              sx={{
                textTransform: 'none',
                mb: 1,
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                '&:not(:last-child)': { mr: 1 },
              }} onClick={handleAddToCart}>
              <AddShoppingCartIcon />Add
            </Button>


          </CardContent>

        </Card>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
        message="Keranjang Telah penuh atau Silahkan pilih Stock Type! "
      />

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent sx={{ p: 0 }}>
          <Slider {...settings}>
            {product.photo_brand?.map(photo => (
              <CardMedia
                key={photo.id_photo}
                component="img"
                image={`${apiUrl}/uploads/brand_photos/${photo.photo_url}`}
                alt={product.brand}
                sx={{ objectFit: 'contain', borderRadius: '8px', height: 'auto' }}
              />
            ))}
          </Slider>
        </DialogContent>
      </Dialog>
    </Container >
  );
};

export default Purchase;
