import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { Paper, Button, Typography, CircularProgress, Box, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const DocumentList = () => {
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [confirmDialog, setConfirmDialog] = useState({ open: false, id: null });
    const navigate = useNavigate();

    // Fetch documents on initial load
    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const response = await axios.get(`${apiUrl}api/document`);
                setDocuments(response.data.data);  // Save documents to state
                setLoading(false);
            } catch (error) {
                console.error('Error fetching documents:', error);
                setLoading(false);
            }
        };

        fetchDocuments();
    }, []);

    // Show loading state while fetching data
    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    // Add numbering to documents
    const documentsWithNumbering = documents.map((document, index) => ({
        ...document,
        number: index + 1, // Start numbering from 1
    }));

    // Open delete confirmation dialog
    const handleDeleteClick = (id_document) => {
        setConfirmDialog({ open: true, id: id_document });
    };

    // Confirm and delete the document
    const handleConfirmDelete = async () => {
        setLoading(true);  // Show loading spinner while deleting
        try {
            // Mengirimkan permintaan DELETE ke server API
            const response = await axios.get(`${apiUrl}api/document/delete/${confirmDialog.id}`);

            // Periksa apakah response.data.status adalah 'success'
            if (response.data.status === 'success') {
                // Hapus dokumen yang terhapus dari state dokumen
                setDocuments(prevDocuments =>
                    prevDocuments.filter(document => document.id_document !== confirmDialog.id)
                );  // Memfilter dokumen yang id-nya sama dengan confirmDialog.id (yang dihapus)

                setLoading(false);
                setConfirmDialog({ open: false, id: null });
                alert('Document deleted successfully');
            } else {
                // Jika status adalah 'error', tampilkan pesan kesalahan
                setLoading(false);
                setConfirmDialog({ open: false, id: null });
                alert('Failed to delete document');
            }
        } catch (err) {
            console.error('Error deleting document:', err);
            setLoading(false);
            setConfirmDialog({ open: false, id: null });
            alert('An error occurred while deleting the document');
        }
    };




    // Close confirmation dialog
    const handleDialogClose = () => {
        setConfirmDialog({ open: false, id: null });
    };

    // Columns for DataTable
    const columns = [
        {
            name: '#',
            selector: row => row.number,
            width: '50px',
            center: true,
        },
        {
            name: 'Product',
            selector: row => row.brand,
            sortable: true,
        },
        {
            name: 'Document URL',
            cell: row => (
                <Button
                    variant="outlined"
                    color="primary"
                    href={`${apiUrl}/uploads/docs/${row.document_url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Detail
                </Button>
            ),
            sortable: false,
        },
        {
            name: 'Uploaded At',
            selector: row => new Date(row.uploaded_at).toLocaleString(),
            sortable: true,
        },
        {
            name: 'Uploaded By',
            selector: row => row.fullname,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
                <Button
                    variant="outlined"
                    color="warning"
                    onClick={() => handleDeleteClick(row.id_document)}
                >
                    Delete
                </Button>
            ),
            sortable: false,
        },
    ];

    return (
        <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, backgroundColor: '#fafafa' }}>
            <Typography variant="h4" gutterBottom>
                Document List
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/admin/document/add')}
                sx={{ mb: 2 }}
            >
                Add Data
            </Button>
            <DataTable
                columns={columns}
                data={documentsWithNumbering}
                pagination
                highlightOnHover
                striped
                responsive
                customStyles={{
                    rows: {
                        style: {
                            fontSize: '14px',
                        },
                    },
                    headCells: {
                        style: {
                            backgroundColor: '#f4f6f8',
                            fontWeight: 'bold',
                        },
                    },
                    cells: {
                        style: {
                            fontSize: '14px',
                        },
                    },
                }}
            />
            {/* Confirmation Dialog */}
            <Dialog open={confirmDialog.open} onClose={handleDialogClose}>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>Do you want to delete this document? This action cannot be undone.</DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default DocumentList;
