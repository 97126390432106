import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Snackbar,
    Alert,
    Paper,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import DataTable from "react-data-table-component";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
    const [editDialog, setEditDialog] = useState({ open: false, user: null });

    const fetchUsers = async () => {
        try {
            const response = await fetch(`${apiUrl}api/users`);
            const data = await response.json();
            setUsers(data);
        } catch (error) {
            console.error("Error fetching users:", error);
            setSnackbar({ open: true, message: "Failed to fetch users.", severity: "error" });
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleSnackbarClose = () => {
        setSnackbar({ open: false, message: "", severity: "success" });
    };

    const handleEditDialogOpen = (user) => {
        setEditDialog({ open: true, user });
    };

    const handleEditDialogClose = () => {
        setEditDialog({ open: false, user: null });
    };

    const handleEditSubmit = async () => {
        const { user } = editDialog;
        try {
            // Kirim permintaan menggunakan Axios
            const response = await axios.post(`${apiUrl}api/users/update/${user.id_user}`, user, {
                headers: {
                    "Content-Type": "application/json",
                }, withCredentials: false,
            });

            // Jika respons gagal, lemparkan error
            if (response.status !== 200) {
                throw new Error("Failed to update user");
            }

            // Update local state
            setUsers((prevUsers) =>
                prevUsers.map((u) => (u.id_user === user.id_user ? user : u))
            );

            // Tampilkan snackbar sukses
            setSnackbar({ open: true, message: "User updated successfully.", severity: "success" });
            handleEditDialogClose();
        } catch (error) {
            console.error("Error updating user:", error);

            // Tampilkan snackbar error
            setSnackbar({ open: true, message: "Failed to update user.", severity: "error" });
        }
    };

    const columns = [
        {
            name: "#",
            cell: (row, index) => index + 1,
            width: "50px",
        },
        {
            name: "Full Name",
            selector: (row) => row.fullname,
            sortable: true,
            width: "200px",
        },
        {
            name: "Username",
            selector: (row) => row.username,
            sortable: true,
            width: "150px",
        },
        {
            name: "Email",
            selector: (row) => row.email,
            width: "250px",
        },
        {
            name: "Phone",
            selector: (row) => row.phone_number,
            width: "150px",
        },
        {
            name: "Address",
            selector: (row) => row.address,
            width: "300px",
        },
        {
            name: "Gender",
            selector: (row) => row.gender,
            width: "100px",
        },
        {
            name: "Actions",
            cell: (row) => (
                <Box display="flex" gap={1}>
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={() => handleEditDialogOpen(row)}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => alert(`Deleting user ${row.fullname}`)}
                    >
                        Delete
                    </Button>
                </Box>
            ),
            width: "200px",
        },
    ];

    return (
        <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, paddingTop: "20px" }}>
            <Typography variant="h4" gutterBottom sx={{ marginBottom: 2 }}>
                User List
            </Typography>
            <Box sx={{ height: "calc(100vh - 300px)", overflowY: "auto", mb: 2 }}>
                <DataTable
                    columns={columns}
                    data={users}
                    pagination
                    highlightOnHover
                    striped
                    responsive
                    customStyles={{
                        rows: {
                            style: {
                                fontSize: "14px",
                            },
                        },
                        headCells: {
                            style: {
                                backgroundColor: "#f4f6f8",
                                fontWeight: "bold",
                            },
                        },
                        cells: {
                            style: {
                                fontSize: "12px",
                            },
                        },
                    }}
                />
                {/* Snackbar for Notifications */}
                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={4000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                    <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: "100%" }}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>

                {/* Edit Dialog */}
                <Dialog open={editDialog.open} onClose={handleEditDialogClose}>
                    <DialogTitle>Edit User</DialogTitle>
                    <DialogContent>
                        {editDialog.user && (
                            <Box display="flex" flexDirection="column" gap={2} mt={2}>
                                <TextField
                                    label="Full Name"
                                    value={editDialog.user.fullname}
                                    onChange={(e) =>
                                        setEditDialog((prev) => ({
                                            ...prev,
                                            user: { ...prev.user, fullname: e.target.value },
                                        }))
                                    }
                                />
                                <TextField
                                    label="Username"
                                    value={editDialog.user.username}
                                    onChange={(e) =>
                                        setEditDialog((prev) => ({
                                            ...prev,
                                            user: { ...prev.user, username: e.target.value },
                                        }))
                                    }
                                />
                                <TextField
                                    label="Password"
                                    value={editDialog.user.password}
                                    onChange={(e) =>
                                        setEditDialog((prev) => ({
                                            ...prev,
                                            user: { ...prev.user, password: e.target.value },
                                        }))
                                    }
                                />
                                <TextField
                                    label="Email"
                                    value={editDialog.user.email}
                                    onChange={(e) =>
                                        setEditDialog((prev) => ({
                                            ...prev,
                                            user: { ...prev.user, email: e.target.value },
                                        }))
                                    }
                                />
                                <TextField
                                    label="Phone"
                                    value={editDialog.user.phone_number}
                                    onChange={(e) =>
                                        setEditDialog((prev) => ({
                                            ...prev,
                                            user: { ...prev.user, phone_number: e.target.value },
                                        }))
                                    }
                                />

                                <TextField
                                    label="Address"
                                    value={editDialog.user.address}
                                    onChange={(e) =>
                                        setEditDialog((prev) => ({
                                            ...prev,
                                            user: { ...prev.user, address: e.target.value },
                                        }))
                                    }
                                />
                                <TextField
                                    label="Gender"
                                    value={editDialog.user.gender}
                                    onChange={(e) =>
                                        setEditDialog((prev) => ({
                                            ...prev,
                                            user: { ...prev.user, gender: e.target.value },
                                        }))
                                    }
                                />
                            </Box>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleEditDialogClose} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleEditSubmit} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Paper>
    );
};

export default UserList;
